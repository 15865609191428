import React from 'react';
import { DataStore } from '../Common/DataStore';
import { StepProps } from '../Common/Types';
import { NextButton, PrevButton } from '../Common/Buttons';
import './Text.css';

interface TextState {
  header: string;
  manchet: string;
  template: string;
  displayOrder:number;
}

export default class Text extends React.Component<StepProps, TextState> {

  constructor(props: any) {
    super(props);
    this.state = {
      header: DataStore.currentSlide.header,
      manchet: DataStore.currentSlide.manchet,
      template: DataStore.currentSlide.template,
      displayOrder: DataStore.currentSlide.displayOrder
    }
    console.log(DataStore.currentSlide);
  }

  headerChange(event: any) {
    this.setState({ header: event.target.value });
  }

  resetHeader() {
    this.setState({ header: "" });
  }

  manchetChange(event: any) {
    this.setState({ manchet: event.target.value });
  }

  templateChange(event: any) {
    this.setState({ template: event.target.value });
  }

  next() {
    DataStore.currentSlide.header = this.state.header;
    DataStore.currentSlide.manchet = this.state.manchet;
    DataStore.currentSlide.template = this.state.template;
    DataStore.currentSlide.displayOrder = this.state.displayOrder;
    this.props.onContinue();
  }

  render() {
    return (
      <div className={"text-input "+ this.state.template?.toLowerCase().replace(/ /g,'-')+"-template"}>
        <div className="upload" style={{ backgroundImage: "url(" + DataStore.currentSlideImage + "/480?rnd=" + DataStore.imageRnd + ")" }}>
        </div>

        <div className="template">
          <select onChange={(e) => this.templateChange(e)} defaultValue={this.state.template}>
            {DataStore.templates.map((template, id) => {
              return <option key={id} >{template}</option>
            })}
          </select>

          <input type="number" placeholder="Order" value={this.state.displayOrder} onChange={(e) => this.setState({ displayOrder: parseInt(e.target.value) })} />

        </div>
        <div className="header">
          <input type="text" placeholder="Titel" value={this.state.header} onChange={(e) => this.headerChange(e)} />
          <div className="reset-input" onClick={() => this.resetHeader()}><i className="fas fa-times"></i></div>
        </div>
          <div className="manchet">
            <textarea placeholder="Beskrivelse" value={this.state.manchet} onChange={(e) => this.manchetChange(e)} />
          </div>

        <div className="buttons">

          <div className="col-left">
            <PrevButton onClick={() => this.props.onContinue(-1)} />
          </div>
          <div className="col-right">
            <NextButton onClick={() => this.next()} />
          </div>
        </div>
      </div>

    );
  }
}