import React from 'react';
import { DataStore } from '../Common/DataStore';

import { NextButton, PrevButton } from '../Common/Buttons'
import Icon from '../Common/Icons';

import { StepProps } from '../Common/Types';
import './Upload.css';

interface UploadContainerState {
  fileSet: boolean;
  fileUploaded: boolean;
}

export default class Upload extends React.Component<StepProps, UploadContainerState> {

  file: File = null;

  constructor(props: any) {
    super(props);
    this.state = {
      fileSet: false,
      fileUploaded: false
    }
  }

  fileChange(event: any) {
    console.log("fileChange");
    if (event.target.files.length === 0) {
      this.file = null;
      return;
    }
    this.file = event.target.files[0];
    console.log(this.file);
    this.upload(event);
  }

  upload(event: Event) {
    event.preventDefault();
    const data = new FormData()
    data.append('files', this.file);
    data.append('id', DataStore.currentSlideId);
    this.props.onLoading();

    fetch(DataStore.baseurl + '/Admin/Upload', {
      method: 'POST',
      body: data
    }).then(
      response => response.json()
    ).then(
      response => {
        if (response.succes) {

          DataStore.updateCurrentSlide(response.data);
          DataStore.updateImageRnd();

          if (!DataStore.currentSlide.header || DataStore.currentSlide.header.length === 0) {
            DataStore.currentSlide.header = this.file.name.replace(response.data.fileextension, "");
          }

          if (!DataStore.currentSlide.manchet || DataStore.currentSlide.manchet.length === 0) {
            DataStore.currentSlide.manchet = "";
          }

          this.props.onContinue();
        } else {
          this.props.onError('Upload error', response.errorDescription);
        }
      }
    ).catch(
      error => {
        console.log(typeof error);
        console.log(error);
        this.props.onError('Upload error', error + "");
      }
    );
  }

  render() {
    return (
      <form className="uploader" encType="multipart/form-data" >
        <div>
          <div className="upload" style={{ backgroundImage: "url(" + DataStore.currentSlideImage + "/480?rnd=" + DataStore.imageRnd + ")" }}>
          </div>
        </div>
        <div className="buttons">
          <div className="col-left">
            <PrevButton onClick={() => this.props.onContinue(-1)} />
            <label htmlFor="img" className="button">
              <Icon type="camera" />
            </label>
            <input type="file" id="img" name="img" accept="image/jpg, image/png, image/jpeg" onChange={(e) => this.fileChange(e)} />
          </div>

          <div className="col-right">
            {DataStore.currentSlideImage &&
              <NextButton onClick={() => this.props.onContinue()} />
            }
          </div>
        </div>
      </form>
    );
  }
}


