import React from 'react';
import { DataStore, Postcard } from '../Common/DataStore';
import { StepProps } from '../Common/Types';
import { Steps } from './Enums';
// import { BaseButton } from '../Common/Buttons';

import './PostCardList.css';



export default class PostCardList extends React.Component<StepProps, {}> {

  constructor(props: any) {
    super(props);

    DataStore.currentSlideId = null;
    DataStore.currentPostcardId = null;
  }

  add() {
    const data = new FormData()
    data.append('name', 'Navn der ikke kan rettes');
    fetch(DataStore.baseurl + '/Admin/CreatePostcard', {
      method: 'POST',
      body: data
    }).then(
      response => response.json()
    ).then(
      response => {
        if (response.succes) {
          var postcard: Postcard = response.data;
          DataStore.postcards.push(postcard);
          this.open(postcard.id);
        } else {
          this.props.onContinue('Server error', response.errorDescription);
        }
      }
    ).catch(
      error => {
        this.props.onError('Server error', error + "");
      }
    );
  }

  open(id: string) {
    DataStore.currentPostcardId = id;
    this.props.onContinue(Steps.Slides);
  }

  render() {
    return (
      <div  className="inner-content">
      <h1>Postkort</h1>
        <div className="list">
            {DataStore.postcards.map((postcard) => {
              return <div key={postcard.id} onClick={() => this.open(postcard.id)}>{postcard.name}</div>
            })}
        </div>
        <div className="buttons">
          {/* <div className="col-right">
          <BaseButton icon="Add" value="Nyt postkort" title="Tilføj et nyt postkort" onClick={this.add.bind(this)} />
          </div> */}
        </div>
      </div>
    );
  }
}