import React from 'react';
import { DataStore } from '../Common/DataStore';


interface ListState {
  error: string;
  loading: boolean;
}

export default class List extends React.Component<{}, ListState> {

  constructor(props: any) {
    super(props);
    const manifestLink = document.createElement("link");
    manifestLink.rel = "manifest";
    manifestLink.href = "/React/admin-manifest.json";
    document.head.appendChild(manifestLink);

    this.state = {
      error: '',
      loading: true
    }
    this.loadPostCardData();

    DataStore.updateImageRnd();
  }

  loadPostCardData() {
    fetch(DataStore.baseurl + '/Admin/GetPostcards', {
      method: 'GET'
    }).then(
      response => response.json()
    ).then(
      response => {
        if (response.succes) {
          DataStore.postcards = response.data;
          this.setState({ loading: false });
        } else {
          this.error('Get data error', response.errorDescription);
        }
      }
    ).catch(
      error => {
        this.error('Get data error', error + "");
      }
    );
  }

  error(header: String, msg: string) {
    this.setState({ error: header + ' ' + msg, loading: false });
  }

  render() {
    return (
      <div className="ListApp">
        <div>
          {this.state.loading ?
            <div className="loaderContainer">Loading</div>
            :
            <div className="inner-content">
              <h1>Postkort</h1>
              <div className="list">
                {DataStore.postcards.map((postcard) => {
                  return <div key={postcard.id} onClick={() => window.location.href = "/#" + encodeURI(postcard.name)}>{postcard.name}</div>
                })}
              </div>
            </div>
          }
        </div>
        {this.state.error !== '' &&
          <div className="error">{this.state.error}</div>
        }
      </div>
    );
  }
}