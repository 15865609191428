import React from 'react';
import './Client.css';
import { DataStore } from '../Common/DataStore';
import Slide from './Slide';


interface ClientState {
  step: number;
  error: string;
  loading: boolean;
}

export default class Client extends React.Component<{}, ClientState> {

  constructor(props: any) {
    super(props);
    
    const manifestLink = document.createElement("link");
    manifestLink.rel = "manifest";
    manifestLink.href = "/React/client-manifest.json";
    document.head.appendChild(manifestLink);

    this.state = {
      step: 0,
      error: '',
      loading: false
    }

    this.loadPostCardData();
  }


  loadPostCardData() {
    fetch(DataStore.baseurl + '/Admin/GetPostcards', {
      method: 'GET'
    }).then(
      response => response.json()
    ).then(
      response => {
        if (response.succes) {
          let id = 0;
          if(window.location.hash){
            let hashText = decodeURI(window.location.hash).replace('#','');
            let choosenId = response.data.findIndex((item:any)=> item.name === hashText );
            if(choosenId && choosenId > 0){
              id = choosenId;
            }
          }
          
          DataStore.postcards = response.data;
          DataStore.currentPostcardId = DataStore.postcards[id].id;
          DataStore.currentSlideId = DataStore.postcards[id].slides[this.state.step].id;
          this.continue(0);
        } else {
          this.error('Get data error', response.errorDescription);
        }
      }
    ).catch(
      error => {
        this.error('Get data error', error + "");
      }
    );
  }

  continue(step?: number, id?: string) {
    let pcId = DataStore.postcards.findIndex((item:any)=> item.id === DataStore.currentPostcardId );
    var nextStep = this.state.step;
    if (step !== null && step !== undefined) {
      if (step < 1) {
        nextStep +=step;
      } else {
        nextStep = step;
      }
    } else {
      nextStep += 1;
      
    }
    if (nextStep > DataStore.postcards[pcId].slides.length - 1) {
      nextStep = 0;
    } else if (nextStep < 0) {
      nextStep = DataStore.postcards[pcId].slides.length - 1;
    }
    
    DataStore.currentSlideId = DataStore.postcards[pcId].slides[nextStep].id;
    this.setState({ step: nextStep, loading: false });
  }

  error(header: String, msg: string) {
    this.setState({ error: header + ' ' + msg, loading: false });
  }

  loading() {
    this.setState({ loading: true });
  }

  render() {
    const contentClass = (this.state.loading || this.state.error !== '') ? 'content blur' : 'content';
    return (
      <div className="ClientApp">
        <div className={contentClass}>
          <Slide onContinue={this.continue.bind(this)} />
        </div>
        {this.state.loading &&
          <div className="loader">Loading</div>
        }
        {this.state.error !== '' &&
          <div className="error">{this.state.error}</div>
        }
      </div>
    );
  }
}