import React from 'react';
import { DataStore, Slide } from '../Common/DataStore';
import { StepProps } from '../Common/Types';
import { PrevButton, BaseButton } from '../Common/Buttons';
import './SlideList.css';


interface ListState {
  fileSet: boolean;
}

export default class SlideList extends React.Component<StepProps, ListState> {

  constructor(props: any) {
    super(props);
    this.state = {
      fileSet: false
    }
  }

  add() {
    const data = new FormData()
    data.append('id', DataStore.currentPostcardId);
    fetch(DataStore.baseurl + '/Admin/CreateSlide', {
      method: 'POST',
      body: data
    }).then(
      response => response.json()
    ).then(
      response => {
        if (response.succes) {
          response.data.template = DataStore.templates[0];
          DataStore.currentPostcard.slides.push(response.data);
          this.open(response.data.id);
        } else {
          this.props.onContinue('Server error', response.errorDescription);
        }
      }
    ).catch(
      error => {
        this.props.onError('Server error', error + "");
      }
    );
  }

  open(id: string) {
    DataStore.currentSlideId = id;
    this.props.onContinue();
  }

  changeOrder(id: string, direction: number) {
    let slides = DataStore.currentSlides;
    let slideIndex = slides.findIndex(s => s.id === id);
    let swapSlideIndex = slideIndex + direction;
    if (swapSlideIndex >= 0 && swapSlideIndex < slides.length) {
      
      let swapSlideOrder = slides[swapSlideIndex].displayOrder;
      if(swapSlideOrder === slides[slideIndex].displayOrder ){
        swapSlideOrder += direction;
      }

      slides[swapSlideIndex].displayOrder = slides[slideIndex].displayOrder;
      slides[slideIndex].displayOrder = swapSlideOrder;

      this.updateSlide(slides[slideIndex]).then(response => {
        this.updateSlide(slides[swapSlideIndex]).then(response => {
          this.forceUpdate();
        });
      })
    }
  }

  async updateSlide(slide: Slide) {
    try {
      const response = await fetch(DataStore.baseurl + '/Admin/AddOrUpdateSlide', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify(slide)
      });
      const response_1 = await response.json() // if the response is a JSON object
        ;
      if (response_1.succes) {
        DataStore.currentSlides = response_1.data.slides;
      } else {
        this.props.onError('Server error', response_1.errorDescription);
      }
    } catch (error) {
      console.log(typeof error);
      console.log(error);
      this.props.onError('Server error: ', error + "");
    }
  }

  render() {    
    return (
      <div className="inner-content">
        <h1>Billeder</h1>
        <div className="list">
          {DataStore.currentSlides.map((slide) => {
            return <div key={slide.id}><span onClick={() => this.open(slide.id)}>{slide.header}</span> <div className="btn-order" onClick={() => this.changeOrder(slide.id, -1)}><i className="fas fa-arrow-up"></i></div><div className="btn-order" onClick={() => this.changeOrder(slide.id, 1)}><i className="fas fa-arrow-down"></i></div></div>
          })}
        </div>
        <div className="buttons">
          <div className="col-left">
            <PrevButton onClick={() => this.props.onContinue(-1)} />
          </div>
          <div className="col-right">
            <BaseButton icon="Add" value="Nyt slide" title="Tilføj et nyt slide" onClick={this.add.bind(this)} />
          </div>
        </div>
      </div>
    );
  }
}