import React from "react";
import './App.css';

import Client from "./Client/Client";
import Admin from "./Admin/Admin";
import List from "./List/List";

import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/Admin" element={<Admin />} />
                <Route path="/List" element={<List />} />
                <Route path="/" element={<Client />} />
            </Routes>
        </BrowserRouter>
    );
}
