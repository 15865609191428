import React from 'react';
import { DataStore } from '../Common/DataStore';
import { StepProps } from '../Common/Types';
import { Steps } from './Enums';
import { PrevButton, CancelButton, BaseButton } from '../Common/Buttons';

export default class Result extends React.Component<StepProps, {}> {

  save() {
    // const data = new FormData();
    // data.append('slide', JSON.stringify(DataStore.currentSlide));
    this.props.onLoading();

    fetch(DataStore.baseurl + '/Admin/AddOrUpdateSlide', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(DataStore.currentSlide)
    }).then(
      response => response.json() // if the response is a JSON object
    ).then(
      response => {
        if (response.succes) {
          DataStore.currentSlideId = null;
          DataStore.currentSlides = response.data.slides;
          this.props.onContinue(Steps.Slides);
        } else {
          this.props.onError('Server error', response.errorDescription);
        }
      }
    ).catch(
      error => {
        console.log(typeof error);
        console.log(error);
        this.props.onError('Server error: ', error + "");
      }
    );
    this.props.onContinue();
  }

  render() {
    let currentSlide = DataStore.currentSlide;
    console.log(DataStore.currentSlide);

    const gotHeader = currentSlide?.header && currentSlide.header.replace(/ /g, "") !== "";
    const gotManchet = currentSlide?.manchet && currentSlide.manchet.replace(/ /g, "") !== "";

    return (
      <div className={"result slide " + currentSlide.template?.toLowerCase().replace(/ /g, '-') + "-template"}>
        <div>
          <div className="slide-image" style={{ backgroundImage: "url(" + DataStore.currentSlideImage + "/480?rnd=" + DataStore.imageRnd + ")" }}></div>

          <div className="slide-text">
            <div className="slide-text-content">
              {gotHeader && currentSlide.template !== DataStore.templates[0] &&
                <div className="header">
                  {currentSlide.header}
                </div>
              }

              {gotManchet &&
                <div className="manchet">
                  {currentSlide.manchet}
                </div>
              }

            </div>
          </div>
        </div>

        <div className="buttons">

          <div className="col-left">
            <PrevButton onClick={() => this.props.onContinue(-1)} />
          </div>
          <div className="col-right">
            <CancelButton onClick={() => this.props.onCancel()} />
            <BaseButton icon="Save" title="Gem slide" onClick={this.save.bind(this)} />
          </div>
        </div>
      </div>
    );
  }
}