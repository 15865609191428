
export interface Slide {
    id: string;
    postcardId: string;
    template: string;
    filename: string;
    fileextension: string;
    header: string;
    manchet: string;
    timestamp: Date;
    longitude: Number;
    latitude: Number;
    width: number;
    height: number;
    recorded: Date;
    displayOrder: number;
}

export interface Postcard {
    id: string;
    name: string;
    slides: Array<Slide>;
}

export class DataStore {
    static postcards: Array<Postcard>;

    static templates = ["Slide", "Slide with header", "Sectionpage"];
    static imageRnd = 0;

    static get currentPostcard(): Postcard {
        return (DataStore.currentPostcardId) ? DataStore.postcards.find(postcard => postcard.id === DataStore.currentPostcardId) : null;
    }

    static get currentSlides(): Array<Slide> {
        // DataStore.currentPostcard?.slides?.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        return DataStore.currentPostcard?.slides;
    }
    static set currentSlides(slides:Array<Slide>) {
        if(DataStore.currentPostcard?.slides){
            console.log(slides);
            DataStore.currentPostcard.slides = slides;
        }
    }

    static get currentSlide(): Slide {
        return (DataStore.currentPostcardId && DataStore.currentSlideId) ? DataStore.postcards.find(postcard => postcard.id === DataStore.currentPostcardId).slides.find(slide => slide.id === DataStore.currentSlideId) : null;
    }

    static updateCurrentSlide(value: Slide) {
        let slide = DataStore.currentSlide;

        let { filename, fileextension, width, height, recorded, latitude, longitude } = value;

        if (slide != null) {
            slide = Object.assign(slide, { filename, fileextension, width, height, recorded, latitude, longitude });
        }
    }

    static updateImageRnd(){
        DataStore.imageRnd = Math.floor(Math.random()*10000);
    }

    static get currentSlideImage() {
        return (DataStore.currentPostcardId && DataStore.currentSlideId && DataStore.currentSlide) ? this.baseurl + this.imageFolder + DataStore.currentSlide.id : null;
    }


    static currentPostcardId: string;
    static currentSlideId: string;

    static get baseurl(): string {
        return (window.location.href.indexOf('localhost:3000') === -1) ? "" : "http://postkort.marten.dk";
    }
    static readonly imageFolder: string = "/Image/Get/";

}