import React from 'react';


interface SpinnerProps {
    show:boolean
  }

export const Spinner = (props:SpinnerProps) => <div id="Spinner" className={ (props.show) ? "":"hidden"}>
    <div className="loader loader1"></div>
    <div className="loader loader2"></div>
    <div className="loader loader3"></div>
    <div className="loader loader4"></div>
</div>;