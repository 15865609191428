import React from 'react';
import './Admin.css';
import { DataStore } from '../Common/DataStore';
import Upload from './Upload';
import Text from './Text';
import Result from './Result';
import SlideList from './SlideList';
import PostCardList from './PostCardList';
import { Steps } from './Enums';


interface AdminState {
  step: number;
  error: string;
  loading: boolean;
}

export default class Admin extends React.Component<{}, AdminState> {

  constructor(props: any) {
    super(props);
    const manifestLink = document.createElement("link");
    manifestLink.rel = "manifest";
    manifestLink.href = "/React/admin-manifest.json";
    document.head.appendChild(manifestLink);

    this.state = {
      step: 0,
      error: '',
      loading: false
    }
    this.loadPostCardData();
    
    DataStore.updateImageRnd();
  }

  loadPostCardData() {
    fetch(DataStore.baseurl + '/Admin/GetPostcards', {
      method: 'GET'
    }).then(
      response => response.json()
    ).then(
      response => {
        if (response.succes) {
          DataStore.postcards = response.data;
          this.continue(1);
        } else {
          this.error('Get data error', response.errorDescription);
        }
      }
    ).catch(
      error => {
        this.error('Get data error', error + "");
      }
    );
  }

  continue(step?: number, id?: string) {
    var nextStep = this.state.step + 1;

    if (step) {
      if (step < 1) {
        nextStep = this.state.step + step;
      } else {
        nextStep = step;
      }
    }
    if (nextStep > 5) {
      nextStep = 0;
    }
    this.setState({ step: nextStep, loading: false });
  }

  cancel() {
    var nextStep = 2;
    this.setState({ step: nextStep, loading: false });
  }

  error(header: String, msg: string) {
    this.setState({ error: header + ' ' + msg, loading: false });
  }

  loading() {
    this.setState({ loading: true });
  }

  render() {
    
    //TODO Skift mellem portrait og landsacpe
    const contentClass = (this.state.loading || this.state.error !== '') ? 'content blur' : 'content';
    return (
      <div className="AdminApp">
        <div className={contentClass}>
          {this.state.step === 0 &&
            <div className="loaderContainer">Starting</div>
          }
          {this.state.step === Steps.Postcards &&
            <PostCardList onContinue={this.continue.bind(this)} onError={this.error.bind(this)} />
          }
          {this.state.step === Steps.Slides &&
            <SlideList onContinue={this.continue.bind(this)} onError={this.error.bind(this)} />
          }
          {this.state.step === Steps.Upload &&
            <Upload onLoading={this.loading.bind(this)} onContinue={this.continue.bind(this)} onCancel={this.cancel.bind(this)} onError={this.error.bind(this)} />
          }
          {this.state.step === Steps.Text &&
            <Text onContinue={this.continue.bind(this)} onCancel={this.cancel.bind(this)} />
          }
          {this.state.step === Steps.Accept &&
            <Result onLoading={this.loading.bind(this)} onError={this.error.bind(this)} onContinue={this.continue.bind(this)} onCancel={this.cancel.bind(this)} />
          }
        </div>
        {this.state.loading &&
          <div className="loaderContainer">Loading</div>
        }
        {this.state.error !== '' &&
          <div className="error">{this.state.error}</div>
        }
      </div>
    );
  }
}