import React from 'react';

interface IIconType {
  type?: string;
}

export default function Icon(props:IIconType) {
  if(props.type && props.type.length > 0){
  switch (props.type.toLowerCase()) {
    case "back":
      return <i className="fas fa-arrow-left"></i>;
    case "forward":
      return <i className="fas fa-arrow-right"></i>;
    case "save":
      return <i className="fas fa-save"></i>;
    case "add":
      return <i className="fas fa-plus"></i>;
      case "cancel":
        return <i className="fas fa-times"></i>;
    case "upload":
      return <i className="fas fa-arrow-up"></i>;
    case "image":
      return <i className="fas fa-camera"></i>;
    default:
      return <i className={"fas fa-" + props.type?.toLowerCase()}></i>;
  }
}

  return (null);
}
