import React from 'react';
import Icon from './Icons';


interface IButton {
  onClick: Function;
}

export function NextButton(props: IButton) {
  return <BaseButton icon="Forward" title="Fortsæt" onClick={() => props.onClick()} />
}

export function PrevButton(props: IButton) {
  return <BaseButton icon="Back" title="Tilbage" onClick={() => props.onClick()} />
}

export function CancelButton(props: IButton) {
  return <BaseButton icon="Cancel" title="Afbryd" onClick={() => props.onClick()} />
}

export function SaveButton(props: IButton) {
  return <BaseButton icon="Save" title="Gem" onClick={() => props.onClick()} />
}

interface IBaseButton extends IButton {
  icon?: string;
  value?: any;
  title: string;
}

export function BaseButton(props: IBaseButton) {
  let cn = "button";
  if ( !("ontouchstart" in window)) {
    cn += " useHover";
  }
  if (props.icon) {
    cn += " icon-button " + props.icon.toLowerCase() + "-icon-button";
  }
  if (props.value) {
    cn += " text-button";
  }
  return <button title={props.title} onClick={(e) => props.onClick(e)} className={cn}>
    <Icon type={props.icon} />
    {props.value &&
      <span>{props.value}</span>
    }
  </button>
}


export function FullscreenButton(props: IButton) {
  let cn = "fullscreen-button";
  if ( !("ontouchstart" in window)) {
    cn += " useHover";
  }
  return (
    <button className={cn} title="Fullscreen" onClick={() => props.onClick()}>
      <svg width="20" height="20">
        <rect x="0" y="0" width="8" height="3" />
        <rect x="0" y="0" width="3" height="8" />
        <rect x="12" y="0" width="8" height="3" />
        <rect x="17" y="0" width="3" height="8" />
        <rect x="0" y="17" width="8" height="3" />
        <rect x="0" y="12" width="3" height="8" />
        <rect x="12" y="17" width="8" height="3" />
        <rect x="17" y="12" width="3" height="8" />
      </svg>
    </button>
  )
}


export function ExitFullscreenButton(props: IButton) {
  let cn = "fullscreen-button";
  if (!window.ontouchstart) {
    cn += " useHover";
  }
  return (
    <button className={cn} title="Exit fullscreen" onClick={() => props.onClick()}>
      <svg width="20" height="20">
        <rect x="0" y="5" width="8" height="3" />
        <rect x="5" y="0" width="3" height="8" />
        <rect x="12" y="5" width="8" height="3" />
        <rect x="12" y="0" width="3" height="8" />
        <rect x="0" y="12" width="8" height="3" />
        <rect x="5" y="12" width="3" height="8" />
        <rect x="12" y="12" width="8" height="3" />
        <rect x="12" y="12" width="3" height="8" />
      </svg>
    </button>
  )
}
