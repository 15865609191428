import React from 'react';
import '../Common/Spinner.css';
import { StepProps } from '../Common/Types';
import { DataStore } from '../Common/DataStore';
import { Spinner } from '../Common/Spinner';

import { NextButton, PrevButton, FullscreenButton, ExitFullscreenButton } from '../Common/Buttons';

interface SlideState {
  step: number;
  error: string;
  loading: boolean;
  preLoading: boolean;
  fullscreen: boolean;
  previewImageUrl: string;
  mainImageUrl: string;
  ratio: string;
}

export default class Slide extends React.Component<StepProps, SlideState> {

  touchPos = -1;

  constructor(props: any) {
    super(props);
    this.state = {
      step: 0,
      error: '',
      preLoading: true,
      loading: true,
      fullscreen: false,
      previewImageUrl: '',
      mainImageUrl: '',
      ratio: ''
    }
  }

  toggleFullScreen() {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen = docEl.requestFullscreen;
    var cancelFullScreen = doc.exitFullscreen;

    if (!doc.fullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }


    this.setState({ fullscreen: !doc.fullscreenElement });
    this.lockOrientation();
  }

  lockOrientation() {
    try {
      let orientation: OrientationLockType = "landscape";
      if (typeof window.screen != "undefined" && typeof window.screen.orientation != "undefined" && typeof window.screen.orientation.lock != "undefined") {
        window.screen.orientation.lock(orientation).then().catch();
      }
      // @ts-ignore  
      else if (typeof ScreenOrientation != "undefined" && typeof ScreenOrientation.lock != "undefined") {
        // @ts-ignore  
        ScreenOrientation.lock(orientation);
      }
    } catch (error) {
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setImages();
    }, 500);

    document.addEventListener("keydown", this.checkForKeyPaging.bind(this))
  }

  prev() {
    this.setState({ loading: true, preLoading: true });
    setTimeout(() => { this.props.onContinue(-1); this.setImages(); }, 450);

  }

  next() {
    this.setState({ loading: true, preLoading: true });
    setTimeout(() => { this.props.onContinue(); this.setImages(); }, 450);
  }

  setImages() {
    const currentSlide = DataStore.currentSlide;
    let ratio = "";
    if (currentSlide && currentSlide.width && currentSlide.height) {
      const ratioThreshold = 0.8
      let r = currentSlide.width / currentSlide.height;
      let wr = window.innerWidth / window.innerHeight;
      if (r < wr * ratioThreshold || r > wr / ratioThreshold) {
        ratio = " different-aspect-ratio";
      }
    }

    let previewImg = DataStore.currentSlideImage + "/480";
    let mainImg = DataStore.currentSlideImage + ((Math.max(window.innerWidth, window.innerHeight) <= 1920) ? "/1920" : "");

    this.setState({ previewImageUrl: previewImg, mainImageUrl: mainImg, ratio: ratio });
  }


  startSlide(pos: number) {
    this.touchPos = pos;
  }

  endSlide(pos: number) {
    console.log("endSlide " + Math.abs(this.touchPos - pos));
    if (Math.abs(this.touchPos - pos) > 100) {
      if ((this.touchPos - pos) > 0) {
        this.next();
      } else {
        this.prev();
      }
    }
  }

  checkForKeyPaging(e: KeyboardEvent) {
    console.log(e.code);
    if (e.code == "ArrowRight" || e.code == "ArrowDown" || e.code == "Space") {
      this.next();
    }
    if (e.code == "ArrowLeft" || e.code == "ArrowUp") {
      this.prev();
    }
  }

  render() {
    const currentSlide = DataStore.currentSlide;

    let fsBtn = <FullscreenButton onClick={this.toggleFullScreen.bind(this)} />
    if (this.state.fullscreen) {
      fsBtn = <ExitFullscreenButton onClick={this.toggleFullScreen.bind(this)} />
    }

    let template = (currentSlide?.template) ? currentSlide.template.toLocaleLowerCase().replace(/ /g, "-") + "-template" : 'slide-template';

    const gotHeader = currentSlide?.header && currentSlide.header.replace(/ /g, "") !== "" && currentSlide?.template !== DataStore.templates[0];
    const gotManchet = currentSlide?.manchet && currentSlide.manchet.replace(/ /g, "") !== "";

    return (
      <div>
        {currentSlide &&

          <div id="Slide" className={"slide " + template} onTouchStart={(e) => { this.startSlide(e.touches[0].pageX) }} onTouchEnd={(e) => this.endSlide(e.touches[0].pageX)} onMouseDown={(e) => { this.startSlide(e.clientX) }} onMouseUp={(e) => this.endSlide(e.clientX)} >

            <img id="PreviewImage" draggable="false" onLoad={() => this.setState({ preLoading: false })} src={this.state.previewImageUrl} alt="" className={"slide-image" + this.state.ratio + (this.state.preLoading ? " hidden" : "")} />
            <img id="MainImage" draggable="false" onLoad={() => this.setState({ preLoading: false, loading: false })} src={this.state.mainImageUrl} alt="" className={"slide-image" + this.state.ratio + (this.state.loading ? " hidden" : "")} />
            {(gotManchet || gotHeader) &&
              <div className={"slide-text" + (this.state.preLoading ? " hidden" : "")} >
                <div className="slide-text-content">
                  {gotHeader &&
                    <div className="header">
                      {currentSlide.header}
                    </div>
                  }

                  {gotManchet &&
                    <div className="manchet">
                      {currentSlide.manchet}
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        }
        <Spinner show={this.state.loading} />
        <PrevButton onClick={this.prev.bind(this)} />
        <NextButton onClick={this.next.bind(this)} />
        {/* <a href="https://developer.mozilla.org/en-US/docs/Web/API/Notification">Notifikationer</a>
        <a href="https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen">A2HS</a>*/}
        {fsBtn}
      </div>
    );
  }
}